import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import {Table, Button, Icon, Container, Segment} from "semantic-ui-react";
import Loader from "../loader";
import { AppError } from "../appMessage";
import { CatalogProvider, useCatalogActions } from "../../context/dashboardStore";

const TableRow = (item) => {
    return (
        <Table.Row key={item.id}>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell singleLine>{item.appVersion}</Table.Cell>
            <Table.Cell>{item.description}</Table.Cell>
            <Table.Cell textAlign='center'>
                <Button icon as={Link} to={`/?bundle=${item.name}`} >
                    <Icon name="play"/>
                </Button>
            </Table.Cell>
        </Table.Row>
    );
}

const filteredCatalogItem = (items, filters) => {
    if(filters.length > 0) {
        return items.filter(i => filters.includes(i.id));
    }
    return items;
}

const CatalogTable = ({catalog}) => {
    const [ , { reloadCatalog } ] = useCatalogActions();
    const [keywordsFilter, setKeywordsFilter] = useState([]);
    const refreshButtonStyle = {marginBottom: "1em"};
    const handleFilterChange = useCallback((e, { value }) => {
        const subSets = value.map(k => catalog.data.index[k]);
        if(subSets.length === 0) setKeywordsFilter([]);
        if(subSets.length === 1) setKeywordsFilter(Array.from(subSets[0]));
        const finalSet = new Set();
        subSets.forEach(subSet => {
            for(let item of subSet) {
                finalSet.add(item);
            }
        })
        setKeywordsFilter(Array.from(finalSet));
    }, [catalog]);

    const keywordsOptions = useMemo(() => {
        return Object.keys(catalog.data.index).map(k => ({ key:k, value:k, text:k }));
    }, [catalog]);

    const refreshCatalog = useCallback(
        () => reloadCatalog().then(_ => setKeywordsFilter([])),
        [reloadCatalog]
    );

    if(catalog.fetching) return <Loader message="Retrieving Catalog..."/>;
    if(catalog.error) return <AppError message="Something wrong happened while retrieving the catalog"/>

    return (
        <Segment raised>
            <Container style={refreshButtonStyle} textAlign="right">
                <Button icon labelPosition="left" onClick={refreshCatalog}>
                    <Icon name="refresh" />
                    Refresh catalog
                </Button>
            </Container>
            <Container>
                <Dropdown
                    name="keywords"
                    fluid
                    placeholder="keywords to filter bundles"
                    clearable
                    multiple
                    search
                    selection
                    defaultValue={[]}
                    options={keywordsOptions || []}
                    onChange={handleFilterChange}
                />
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine>Bundle name</Table.HeaderCell>
                            <Table.HeaderCell>Version</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>New Demo</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { filteredCatalogItem(catalog.data.items, keywordsFilter).map(item => TableRow(item))}
                    </Table.Body>
                </Table>
            </Container>
        </Segment>
    );
}

const CatalogView = () =>
    <CatalogProvider>
        {catalog => <CatalogTable catalog={catalog} />}
    </CatalogProvider>

export default CatalogView;
