const SMILE_CAS_URL = 'https://sso.smile.fr/cas';

const configMap = {
    serviceAuthRedirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL,
    catalogClientUrl: process.env.REACT_APP_CATALOG_CLIENT_URL,
    teamClientUrl: process.env.REACT_APP_TEAM_CLIENT_URL,
    appClientUrl: process.env.REACT_APP_APP_CLIENT_URL,
    costClientUrl: process.env.REACT_APP_COST_CLIENT_URL,
    bearerUrl: process.env.REACT_APP_BEARER_URL,
    webSshUrl: process.env.REACT_APP_WEBSSH_URL
};

module.exports = {
    SMILE_CAS_LOGIN_REDIRECT_URL:`${SMILE_CAS_URL}/login?service=${configMap['serviceAuthRedirectUrl']}`,
    SMILE_CAS_LOGOUT_URL:`${SMILE_CAS_URL}/logout`,
    BEARER_URL: configMap.bearerUrl,
    CATALOG_CLIENT_URL: configMap.catalogClientUrl,
    TEAM_CLIENT_URL: configMap.teamClientUrl,
    APP_CLIENT_URL: configMap.appClientUrl,
    COST_CLIENT_URL: configMap.costClientUrl,
    WEB_SSH_URL: configMap.webSshUrl
};