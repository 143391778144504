import WebSocketAsPromised from 'websocket-as-promised';
import { WEB_SSH_URL } from "../config";

export const SShActions = {
    AuthRequest:"authreq",
    AuthOk:"authok",
    AuthKo:"authko",
    In:"in",
    Out:"out",
    Err:"err",
}

const createSSHWebSocket = async (authorizationToken) => {
    const ws = new WebSocketAsPromised(`${WEB_SSH_URL}`, {
        packMessage: data => JSON.stringify(data),
        unpackMessage: data => JSON.parse(data)
    });
    await ws.open().then(_ => {
        return ws.sendPacked({data: { action:SShActions.AuthRequest, payload:authorizationToken}});
    });
    return ws;
}

export default createSSHWebSocket;