import { useEffect } from "react";
import { createStore, createHook } from 'react-sweet-state';
import { fetchCatalog } from "./catalogActions";
import { fetchTeamMembers,
    fetchRoles, addNewRole, removeRole, updateRole,
    fetchTeams, updateTeam } from "./teamActions";
import { fetchApps, createApp, startApp, stopApp, deleteApp, updateAppLifeCycle } from "./appActions";
import { fetchAppCosts } from "./appCostsActions";
import { NETWORK_ONLY } from "./cachePolicy";

// Global store Initial state
const initialState = {
    catalog:{ fetching:false },
    teamMembers:{ fetching:false, data:{} },
    roles: { fetching:false },
    teams: { fetching:false },
    apps: { fetching:false },
    appCosts: { fetching:false, data:{} }
};

// All actions
const actions = {
    fetchCatalog,
    reloadCatalog:() => async ({ dispatch }) => dispatch(fetchCatalog(NETWORK_ONLY)),
    fetchTeamMembers,
    fetchRoles,
    addNewRole,
    updateRole,
    removeRole,
    fetchTeams,
    updateTeam,
    fetchApps,
    reloadApps:() => async ({ dispatch }) => dispatch(fetchApps(NETWORK_ONLY)),
    createApp,
    startApp,
    stopApp,
    deleteApp,
    updateAppLifeCycle,
    fetchAppCosts
};

// Global Store
const dashboardStore = createStore({ initialState, actions, name:"dashboardStore" });

// Catalog hook and provider
const catalogSelector = (state) => state.catalog;

const useCatalogStore = createHook(dashboardStore, {selector: catalogSelector});

export const useCatalogActions = createHook(dashboardStore, {selector: null});

export const CatalogProvider = ({children}) => {
    const [catalogStore, {fetchCatalog}] = useCatalogStore();
    useEffect(() => {
        if(!catalogStore.data) fetchCatalog();
    }, [catalogStore, fetchCatalog]);

    const catalog = {
        ...catalogStore,
        data:catalogStore.data || {items:[], index:{}}
    };
    return children(catalog);
}

// TeamMembers hook and provider
const teamMembersByIdSelector = (state, arg) => ({ data: state.teamMembers.data[arg], fetching:state.teamMembers.fetching, error:state.teamMembers.error});

const useTeamMemberByIdStore = createHook(dashboardStore, { selector: teamMembersByIdSelector});

export const TeamMembersProvider = ({children, teamId}) => {
    const [teamMembers, {fetchTeamMembers}] = useTeamMemberByIdStore(teamId);
    useEffect(() => {
        if(!teamMembers.data) fetchTeamMembers(teamId);
    }, [teamId, teamMembers, fetchTeamMembers]);

    return children(teamMembers);
};

// Teams hook and provider
const teamsAndRolesSelector = (state) => ({ teams:state.teams, roles: state.roles });
const teamsSelector = (state) => state.teams;
const useTeamsAndRolesStore = createHook(dashboardStore,{selector: teamsAndRolesSelector});

export const useTeamsActions = createHook(dashboardStore, { selector:null});
export const useTeamsStoreAndActions = createHook(dashboardStore, { selector: teamsSelector});

export const TeamsAndRolesProvider = ({children}) => {
    const [teamsStore, {fetchTeams, fetchRoles}] = useTeamsAndRolesStore();
    useEffect(() => {
        if(!teamsStore.teams.data) fetchTeams();
    }, [teamsStore, fetchTeams]);

    useEffect(() => {
        if(!teamsStore.roles.data) fetchRoles();
    }, [teamsStore, fetchRoles]);

    const subStore = {
        teams: { ...teamsStore.teams, data: teamsStore.teams.data || [] },
        roles: { ...teamsStore.roles, data: teamsStore.roles.data || [] },
    };
    return children(subStore);
}

// Roles hook and provider
export const useRolesActions = createHook(dashboardStore, {selector:null});

// Apps hook and provider
const appsSelector = (state) => state.apps;
const useAppsStore = createHook(dashboardStore, {selector: appsSelector});
export const useAppsActions = createHook(dashboardStore, {selector:null});
export const AppsProvider = ({children}) => {
    const [appStore, {fetchApps}] = useAppsStore();
    useEffect(() => {
        if(!appStore.data) fetchApps();
    }, [appStore, fetchApps]);

    const apps = {
        ...appStore,
        data:appStore.data || []
    };
    return children(apps);
}

// AppCosts hook and provider
const appCostsByTeamIdSelect = (state, arg) => ({ data: state.appCosts.data[arg], fetching:state.appCosts.fetching, error:state.appCosts.error});
const useAppCostByTeamIdStore = createHook(dashboardStore, { selector: appCostsByTeamIdSelect});

export const useAppCostsActions = createHook(dashboardStore, {selector:null});

export const AppCostsProvider = ({children, teamId, ymTs}) => {
    const [appCosts, {fetchAppCosts}] = useAppCostByTeamIdStore(teamId);
    useEffect(() => {
        if(!appCosts.data || !appCosts.data[ymTs]) fetchAppCosts(teamId, ymTs);
    }, [teamId, appCosts, fetchAppCosts, ymTs]);

    return children(appCosts);
};
