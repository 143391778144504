import React, { useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal, Button, Container, Form, Divider } from "semantic-ui-react";
import { SelectFormikSemantic, DropDownFormikSemantic} from "../formHelper";
import Loader from "../loader";
import { AppError, AppWarning } from "../appMessage";
import { appNamingConvention, passwordPattern } from "../../schema/app";
import { CatalogProvider, useAppsActions } from "../../context/dashboardStore";

const disclaimerMessage = "By creating this demo app, I make sure to use it wisely, " +
    "I fully understand the related underlying costs of it, that it is not intended for production purpose";

const disclaimerMessageWithCredentials = `${disclaimerMessage}, and I know it will be publicly available from the internet through its hostname and login/password.`;
const disclaimerMessageWithoutCredentials = `${disclaimerMessage}, and I know it will be publicly available from the internet through its hostname and without any credentials`;

const emptyForm = {
    name:'',
    description:'',
    bundleName:'',
    teamId:'',
    withAuth:true,
    login:'',
    password:'',
    disclaimer:false
};

//TODO : Block app creation if app name already exists in current app from same team
const ModalContent = ({closeModal, userTeams, bundleToDeploy, catalog}) => {
    const [, { createApp }] = useAppsActions();

    const bundlesOptions = useMemo(() => {
        if(!!catalog.data && !!catalog.data.items) {
            return catalog.data.items
                .map(bundle => ({key:bundle.id, value:bundle.id, text:`${bundle.name} - ${bundle.appVersion}`}));
        } else {
            return [];
        }
    }, [catalog.data]);

    const teamsOptions = useMemo(() => {
        return userTeams.map(t => ({key:t, value:t, text:t}));
    }, [userTeams]);

    const newAppValidationSchema = useMemo(() => {
        const bundleNames = bundlesOptions.map(opt => opt.key);
        return yup.object().shape({
            teamId:yup
                .string()
                .oneOf(userTeams, "This team does not exists")
                .required("team name is required"),
            bundleName:yup
                .string()
                .oneOf(bundleNames, "This bundle does not exists")
                .required("bundle name is required"),
            name:yup
                .string()
                .matches(appNamingConvention, "Invalid team name (min 3, max 30, alphanumerical, no space)")
                .required('A name is required'),
            description:yup
                .string()
                .required('A description is required'),
            withAuth:yup.boolean(),
            login:yup.string().when("withAuth", {is:true, then: yup.string().required("login is required")}),
            password:yup.string()
                .matches(passwordPattern, "At least 8 characters, include 1 lowercase, 1 uppercase, 1 digit and 1 special character")
                .when("withAuth", {is:true, then: yup.string().required("password is required")}),
            passwordConfirmation:yup.string()
                .oneOf([yup.ref('password'), null], 'Passwords must match')
                .when("withAuth", {is:true, then: yup.string().required("password confirmation is required")})
        });
    }, [userTeams, bundlesOptions]);

    const submitNewApp = (values, { setSubmitting }) => {
        if(!values.withAuth) {
            values.login = '';
            values.password = '';
        }
        createApp({...values, bundleName:`demo-factory-charts/${values.bundleName}`}).then(r => {
            setSubmitting(false);
            closeModal();
        });
    };

    if(catalog.fetching) return (
        <>
            <Modal.Header>New App</Modal.Header>
            <Modal.Content>
                <Loader message="Retrieving Catalog..."/>
            </Modal.Content>
        </>
    );

    if(catalog.error) return (
        <>
            <Modal.Header>New App</Modal.Header>
            <Modal.Content>
                <AppError message="Something wrong happened while retrieving the catalog"/>
            </Modal.Content>
        </>
    );

    if(userTeams.length < 1) return (
        <>
            <Modal.Header>New App</Modal.Header>
            <Modal.Content>
                <AppWarning message="You do not belong to any teams. Please contact the application admin to be assigned to at least one team" />
            </Modal.Content>
        </>
    );

    const selectedBundle = bundlesOptions.find(b => b.value === bundleToDeploy);
    const initialValues = {...emptyForm, teamId: userTeams.length === 1 ? userTeams[0] : '', bundleName: selectedBundle ? selectedBundle.value : ''};

    const disclaimerStyle = {fontWeight:"bold"};

    return (
        <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newAppValidationSchema} onSubmit={submitNewApp} onReset={closeModal}>
            {({ errors, dirty, values, handleChange, handleBlur, setFieldValue, handleSubmit, handleReset, isSubmitting }) => (
                <>
                    <Modal.Header>App creation</Modal.Header>
                    <Modal.Content>
                        <Form loading={isSubmitting}>
                            <Form.Group widths='equal'>
                                <Form.Input label="App name" required name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} placeholder="App name" error={errors.name} />
                                <Form.Field required>
                                    <label>App bundle</label>
                                    <DropDownFormikSemantic fluid selection search required name="bundleName" value={values.bundleName} options={bundlesOptions} onChange={handleChange} placeholder="App Bundle" error={!!errors.bundleName}  />
                                </Form.Field>
                                <SelectFormikSemantic label="Associated team" required name="teamId" value={values.teamId} options={teamsOptions} onChange={handleChange} placeholder="Team association" error={!!errors.teamId} />
                            </Form.Group>
                            <Form.TextArea required label="Description of this application" name="description" value={values.description} onChange={handleChange} onBlur={handleBlur} placeholder="Description" error={errors.description} />
                            <Form.Checkbox name="withAuth" checked={values.withAuth} label="Protect web with login/password" onChange={() => setFieldValue('withAuth', !values.withAuth)} onBlur={handleBlur} error={errors.withAuth} />
                            <Form.Group widths='equal'>
                                <Form.Input required={values.withAuth} disabled={!values.withAuth} label="Login" name="login" value={values.login} onChange={handleChange} onBlur={handleBlur} placeholder="Web login" error={errors.login} />
                                <Form.Input required={values.withAuth} disabled={!values.withAuth} label="password" name="password" type="password" value={values.password} onChange={handleChange} onBlur={handleBlur} placeholder="Web password" error={errors.password} />
                                <Form.Input required={values.withAuth} disabled={!values.withAuth} label="password confirmation" name="passwordConfirmation" type="password" value={values.passwordConfirmation} onChange={handleChange} onBlur={handleBlur} placeholder="password confirmation" error={errors.passwordConfirmation} />
                            </Form.Group>
                            <Divider/>
                            <Form.Checkbox style={disclaimerStyle} name="disclaimer" checked={values.disclaimer} onChange={() => setFieldValue('disclaimer', !values.disclaimer)} label={values.withAuth ? disclaimerMessageWithCredentials : disclaimerMessageWithoutCredentials} error={errors.disclaimer} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="grey"  disabled={isSubmitting} onClick={handleReset}>Cancel</Button>
                        <Button type="submit" disabled={ Object.keys(errors).length>0 || !dirty || isSubmitting || !values.disclaimer } color="blue" onClick={handleSubmit}>Create</Button>
                    </Modal.Actions>
                </>
            )}
        </Formik>
    );
}

const NewAppModalForm = ({open, closeModal, userTeams, bundleToDeploy}) => {
    return (
        <Container>
            <Modal size="large" onClose={closeModal} open={open}>
                <CatalogProvider>
                    {catalog => <ModalContent closeModal={closeModal} userTeams={userTeams} bundleToDeploy={bundleToDeploy} catalog={catalog}/> }
                </CatalogProvider>
            </Modal>
        </Container>
    );
};

export default NewAppModalForm;