export const UserRole = {
    ADMIN:'ADMIN',
    MEMBER:'MEMBER',
    NONE:'NONE'
};

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const userIdPattern = /^[0-9]{3,15}$/

export const UserAssignmentQuery = `
    query { 
        UserAssignment { userId, role, teams } 
    }
`;