export const appNamingConvention = /^[a-zA-Z0-9]{3,30}$/;

// Min 8 chars, at least 1 lowercase, 1 uppercase, 1 digit and 1 special char
export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[/,'"\-_*!&.+?$^|\\(){}]).{8,}$/

export const AppStatus = {
    CREATING:"CREATING",
    STARTING:"STARTING",
    RUNNING:"RUNNING",
    STOPPING:"STOPPING",
    STOPPED:"STOPPED",
    DELETING:"DELETING",
    DELETED:"DELETED",
    ERROR:"ERROR"
};

export const EndOfLifeAction = {
    DO_NOTHING:"DO_NOTHING",
    TO_BE_STOPPED:"TO_BE_STOPPED",
    TO_BE_DELETED:"TO_BE_DELETED"
};

export const AppsQuery = `query {
    Apps {
        id,
        teamId,
        ownerId,
        name,
        bundleName,
        status,
        parameters { description, hostname, login, password, cfStackName, ec2InstanceId },
        auditLog { status, updatedBy, updatedAt },
        endOfLife { action, expirationDate }
    }
}`;

export const AppSshTokenQuery = `query ($id: String!) {
        AppSshToken(id: $id) { token }
}`;

export const CreateAppMutation = `mutation (
        $teamId: String!, 
        $name: String!, 
        $bundleName: String!, 
        $description: String!, 
        $login: String, 
        $password: String
        ) {
        createApp (
            teamId: $teamId, 
            name: $name, 
            bundleName: $bundleName, 
            description: $description, 
            login: $login, 
            password: $password
        ) { 
            error, 
            success, 
            app {
                teamId, 
                id,
                ownerId, 
                name,
                bundleName,
                status,
                parameters { description, hostname, login, password, cfStackName, ec2InstanceId },
                auditLog { status, updatedBy, updatedAt }
            }
        }
    }`;

export const StartAppMutation = `mutation ($id: String!) {
    startApp(id: $id) { error, success, newStatus }
}`;

export const StopAppMutation = `mutation ($id: String!) {
    stopApp(id: $id) { error, success, newStatus }
}`;

export const DeleteAppMutation = `mutation ($id: String!) {
    deleteApp(id: $id) { error, success, newStatus }
}`;

export const UpdateAppLifeCycleMutation = `mutation ($id: String!, $expirationDate: String!, $action: ExpirationAction!) {
    updateAppLifeCycle(id: $id, expirationDate: $expirationDate, action: $action) { error, success }
}`;
