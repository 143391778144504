import React, { useCallback, useState, useMemo } from "react";
import copyToClipboard from "copy-to-clipboard";
import { DateTime } from "luxon";
import { Button, Container, Modal, Table, List, Input, Icon } from "semantic-ui-react";
import { TeamMembersProvider } from "../../context/dashboardStore";
import { AppStatus } from "../../schema/app";
import { AppLoader } from "../appMessage";
import uptimeEstimation from "./uptimeEstimation";
import AppLifeCycle from "./appLifeCycle";
import SshConsole from "./sshConsole";

const resolveUserName = (members, id) => members.find(m => m.userId === id)?.userName || id;

const AuditLog = ({app, members}) => {
    return (
        <List>
            {app.auditLog.map((event, idx) =>
                <List.Item key={idx} >
                    <List.Icon name={event.updatedBy.startsWith('aws') ? 'server' : 'user'} />
                    <List.Content>
                        <List.Header>{event.status}</List.Header>
                        <List.Description>
                            {`${resolveUserName(members, event.updatedBy)} at ${DateTime.fromISO(event.updatedAt).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}`}
                        </List.Description>
                    </List.Content>
                </List.Item>
            )}
        </List>
    );
};

const PasswordDisplay = ({password}) => {
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [copyConfirmation, setCopyConfirmation] = useState('');

    const revealPassword = useCallback(() => {
        setCopyConfirmation('');
        setPasswordInputType("text");
    }, []);

    const hidePassword = useCallback(() => {
        setPasswordInputType("password");
    }, []);

    const copyPassword = useCallback(() => {
        copyToClipboard(password);
        setCopyConfirmation("Copied!");
    }, [password]);

    return (
        <>
            <Input
                transparent
                icon={{name:"eye", link:true, onMouseDown:revealPassword, onMouseUp:hidePassword}}
                type={passwordInputType}
                value={password}
            />
            <Icon style={{marginLeft:"1em"}} link name="copy" onClick={copyPassword} />
            <span>{copyConfirmation}</span>
        </>
    )
}

const AppDetailModal = ({open, closeModal, app, teamMembers}) => {

    const [showSSHConsole, setShowSSHConsole] = useState(false);

    const openSSHConsole = () => {
        setShowSSHConsole(true);
    };

    const closeSSHConsole = () => {
        setShowSSHConsole(false);
    }

    const uptime = useMemo(() => {
        const uptimeMin = uptimeEstimation(app.auditLog || []);
        return (uptimeMin/60).toFixed(2);
    },[app]);

    return (
        <Container>
            <Modal size="large" onClose={closeModal} open={open}>
                <Modal.Header>{app.name}</Modal.Header>
                <Modal.Content>
                    {teamMembers.fetching ? <AppLoader message="Loading details ..." /> :
                        <Table celled striped>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Description</Table.Cell>
                                    <Table.Cell>{app.parameters.description}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Bundle name</Table.Cell>
                                    <Table.Cell>{app.bundleName}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>SSH Console</Table.Cell>
                                    <Table.Cell>
                                        <Button primary disabled={app.status !== AppStatus.RUNNING} onClick={openSSHConsole}>Connect</Button>
                                        <SshConsole app={app} onClose={() => closeSSHConsole()} open={showSSHConsole}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>URL</Table.Cell>
                                    <Table.Cell>
                                        {app.status === AppStatus.RUNNING ?
                                            <a rel="noreferrer" target="_blank" href={`http://${app.parameters.hostname}`}>{`http://${app.parameters.hostname}`}</a>
                                            : `http://${app.parameters.hostname}`
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                {(!!app.parameters.login && !!app.parameters.password) ?
                                    <>
                                        <Table.Row>
                                            <Table.Cell>Login</Table.Cell>
                                            <Table.Cell>{app.parameters.login}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Password</Table.Cell>
                                            <Table.Cell>
                                                <PasswordDisplay password={app.parameters.password}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    </>
                                    : null }
                                <Table.Row>
                                    <Table.Cell>Automatic stop/delete</Table.Cell>
                                    <Table.Cell>
                                        <AppLifeCycle app={app}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>CloudFormation StackName</Table.Cell>
                                    <Table.Cell>{app.parameters.cfStackName}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Instance Id</Table.Cell>
                                    <Table.Cell>{app.parameters.ec2InstanceId}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Estimated uptime duration</Table.Cell>
                                    <Table.Cell>{uptime} hours</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Events</Table.Cell>
                                    <Table.Cell>
                                        <AuditLog app={app} members={teamMembers.data || []} />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={closeModal}>Close</Button>
                </Modal.Actions>
            </Modal>
        </Container>
    );
}

const AppDetailView = ({open, onClose, teamId, app}) => {
    if(open && app) {
        return (
            <TeamMembersProvider teamId={teamId}>
                {teamMembers => <AppDetailModal open={open} closeModal={onClose} app={app} teamMembers={teamMembers} />}
            </TeamMembersProvider>
        )
    }
    return null;
};

export default React.memo(AppDetailView);