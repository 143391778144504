import React from "react";
import {DateTime} from "luxon";
import {Table} from "semantic-ui-react";
import Loader from "../loader";
import {AppError} from "../appMessage";

const totalStyle = {fontWeight:'bold'};

const orderByAppName = (r1, r2) => {
    if(r1.appName < r2.appName) return -1;
    if(r1.appName > r2.appName) return 1;
    return 0;
};

const CostTable = ({teamCosts, ymTs}) => {
    if(teamCosts.fetching) return <Loader message="Retrieving Costs..."/>;
    if(teamCosts.error) return <AppError message="Something wrong happened while retrieving costs"/>

    const dt = DateTime.fromSeconds(ymTs, {zone:"utc"});
    const costLines = teamCosts.data ? teamCosts.data[ymTs] : [];

    if(!costLines || costLines.length === 0) {
        return <p>There is no app costs for {dt.toFormat("MMMM yyyy")} period</p>
    } else {

        const total = costLines.reduce((sum, c) => sum + c.cost, 0);

        return (
            <>
                <p>List costs for {dt.toFormat("MMMM yyyy")} :</p>
                <Table celled compact definition striped>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>App</Table.HeaderCell>
                            <Table.HeaderCell>Cost ($)</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {costLines.sort(orderByAppName).map(c =>
                            <Table.Row key={c.appId}>
                                <Table.Cell collapsing>{c.appName}</Table.Cell>
                                <Table.Cell collapsing>{c.cost.toFixed(2)} $</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell><span style={totalStyle}>{total.toFixed(2)} $</span></Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </>
        );
    }
}

export default CostTable;