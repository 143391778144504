import React, { useState, useCallback } from "react";
import { Button, Icon, Table, Confirm } from "semantic-ui-react";
import { useRolesActions } from "../../context/dashboardStore";
import { UserRole } from "../../schema/userAssignment";

// React memo to re-render only if props are updated
const RowContent = React.memo(({userId, userName, role, defaultAdmin, submitting, toggleAdmin, isMemberOfTeam, removeRoleHandler}) => {
    const [confirmation, setConfirmation] = useState({show:false, message:"", onConfirm:() => {}});

    const onRemove = useCallback(() => {
        const action = () => {
            setConfirmation(prevState => ({...prevState, show:false}));
            return removeRoleHandler();
        };
        return setConfirmation((prevState) => ({
            show: true,
            message:`Are you sure you wants to remove "${userName}" ?`,
            onConfirm: action
        }));
    }, [removeRoleHandler, userName]);

    return (
        <Table.Row>
            <Table.Cell>{userId}</Table.Cell>
            <Table.Cell>{userName}</Table.Cell>
            <Table.Cell collapsing>
                <Confirm
                    open={confirmation.show}
                    content={confirmation.message}
                    onConfirm={confirmation.onConfirm}
                    header='Please confirm'
                    cancelButton='Never mind'
                    confirmButton="Yes, let's do it"
                    onCancel={() => setConfirmation((prevState) => ({...prevState, show:false}))}
                />
                <Button toggle active={role === UserRole.ADMIN} onClick={toggleAdmin} disabled={!!defaultAdmin || submitting }>
                    Admin
                </Button>
                <Button icon disabled={!!defaultAdmin || submitting || isMemberOfTeam} onClick={onRemove}>
                    {isMemberOfTeam ? <Icon name="users"/>: <Icon color="red" name='user delete' />}
                </Button>

            </Table.Cell>
        </Table.Row>
    );
});

// {isMemberOfTeam ? <Button icon="info" data-tooltip="Member of a team" /> : null }

const RoleRow = ({item, isMemberOfTeam}) => {
    const [submitting, setSubmitting] = useState(false);
    const [ , { updateRole, removeRole }] = useRolesActions();
    const { userId, role } = item;

    const toggleAdmin = useCallback((e) => {
        setSubmitting(true);
        const newRole = role === UserRole.ADMIN ? UserRole.MEMBER : UserRole.ADMIN;
        updateRole({userId, role:newRole}).then(r => setSubmitting(false));
    }, [userId, role, updateRole, setSubmitting]);

    const removeRoleHandler = useCallback((e) => {
        setSubmitting(true);
        removeRole({userId});
    }, [userId, removeRole, setSubmitting]);

    return <RowContent {...item} submitting={submitting} toggleAdmin={toggleAdmin} isMemberOfTeam={isMemberOfTeam} removeRoleHandler={removeRoleHandler} />
}

export default RoleRow;
