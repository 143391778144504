import axios from 'axios';
import config from '../config';
import { PayloadException, ApiError } from '../ApiException';

class AppBearerApi {
    static getBearerFromTicket(ticket, origin = null) {
        const originParam = origin ? `&origin=${origin}` : '';
        return axios.get(`${config.BEARER_URL}?ticket=${ticket}${originParam}`)
            .then(response => {
                if(response.status === 200 && response.data && response.data.bearer) {
                    return response.data.bearer;
                }
                throw new PayloadException(response);
            }).catch((error) => {
                throw new ApiError(error);
            });
    }
}

export default AppBearerApi;