
export const RolesQuery = `query { Roles { userId, userName, role, defaultAdmin } }`;

export const AssignRoleMutation = `
    mutation ($userId: String!, $userName: String!, $role: UserRole!) {
        assignRole (userId: $userId, userName: $userName, role: $role) { error, success }
    }
`;

export const UpdateRoleMutation = `
    mutation ($userId: String!, $role: UserRole!) {
        updateRole (userId: $userId, role: $role) { error, success }
    }
`;

export const RemoveRoleMutation =  `
    mutation ($userId: String!) {
        removeUserFromRoles(userId: $userId) {success, error}
    }
`;