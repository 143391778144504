import { DateTime } from "luxon";
const APP_BEARER = 'appbearer';
const APP_BEARER_LIFETIME_MINUTES = 360; // 6 hours

class AppBearer {
    static getBearer() {
        return sessionStorage.getItem(APP_BEARER);
    }

    static setBearer(newAppBearer) {
        sessionStorage.setItem(APP_BEARER, newAppBearer);
    }

    static clearBearer() {
        sessionStorage.removeItem(APP_BEARER);
    }

    static isLoggedIn() {
        const bearer = AppBearer.getBearer();
        return !!bearer;
    }

    static expirationDate() {
        const bearer = AppBearer.getBearer();
        if(bearer) {
            try {
                const json = JSON.parse(Buffer.from(AppBearer.getBearer(), 'base64').toString('utf8'));
                return DateTime.fromSeconds(json.t).toUTC().plus({minutes: APP_BEARER_LIFETIME_MINUTES});
            }
            catch(e) {
                return DateTime.utc();
            }
        } else {
            return DateTime.utc();
        }
    }

    static userInfo() {
        const bearer = AppBearer.getBearer();
        if(bearer) {
            try {
                const json = JSON.parse(Buffer.from(AppBearer.getBearer(), 'base64').toString('utf8'));
                return {
                    uid:json.u,
                    firstName:json.f,
                    lastName:json.l,
                    email:json.e
                };
            }
            catch(e) {
                return {};
            }
        } else {
            return {};
        }
    }
}

export default AppBearer;