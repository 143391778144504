
export const teamNamingConvention = /^[a-zA-Z0-9-]{3,30}$/;

export const TeamsQuery = `query { Teams { teamId, members { userId, userName } } }`;

export const TeamMembersQuery = `
    query ($teamId: String!) {
        TeamMembers (teamId: $teamId) { userId, userName }
    }
`;

export const UpdateTeamMutation = `
    mutation ($teamId: String!, $members: [TeamMemberInput]) {
        updateTeam (teamId: $teamId, members: $members) { error, success }
    }
`;
