import { appSshTokenClient } from "../services/graphqlClient";
import { AppSshTokenQuery } from "../schema/app";
import { NETWORK_ONLY} from "./cachePolicy";

const cachePolicy = { policy: NETWORK_ONLY}

export const getAppSshToken = async (appId) => {
    return appSshTokenClient.query(AppSshTokenQuery, {id: appId}, {...cachePolicy})
        .toPromise()
        .then(r => r.data.AppSshToken.token);
};