import { appCostsClient } from "../services/graphqlClient";
import { TeamCostsByMonthQuery } from "../schema/appCost";
import { CACHE_FIRST } from "./cachePolicy";

export const fetchAppCosts = (teamId, ymTs, policy = CACHE_FIRST) => async ({getState, setState}) => {
    const { appCosts } = getState();
    if(appCosts.fetching) return;
    setState({ appCosts:{ ...appCosts, fetching: true} });

    await appCostsClient.query(TeamCostsByMonthQuery, {teamId, ymTs}, {...policy})
        .toPromise()
        .then(r => {
            const monthlyCls = r.data.TeamCostsByMonth;
            setState({
                appCosts: {
                    data: {...appCosts.data, [teamId]:{...appCosts.data[teamId], [ymTs]: monthlyCls}}},
                error: null,
                fetching:false
            });

        })
        .catch(e => setState({
            appCosts: {...appCosts, fetching:false, error:e }
        }));
};
