import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AuthProvider from "./context/authProvider";
import PrivateRoute from "./components/security/privateRoute";
import Callback from "./pages/callback";
import Home from "./pages/home";
import Catalog from "./pages/catalog";
import Cost from "./pages/cost";
import TeamManagement from "./pages/teamManagement";
import "./App.scss";

const App = () => {
    return (
        <div className="App">
            <AuthProvider>
                    <Router>
                        <Switch>
                            <PrivateRoute exact path="/" component={Home} />
                            <PrivateRoute exact path="/catalog" component={Catalog} />
                            <PrivateRoute exact path="/cost" component={Cost} />
                            <PrivateRoute exact path="/team" component={TeamManagement} />
                            <Route exact path="/callback" component={Callback}/>
                        </Switch>
                    </Router>
            </AuthProvider>
        </div>
    );
}

export default App;
