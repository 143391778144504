import React from "react"
import Layout from "../components/layout";
import CatalogTable from "../components/catalog/catalogTable";

const Catalog = () => {
    return (
        <Layout>
            <CatalogTable/>
        </Layout>);
}

export default Catalog;
