import React, { useMemo } from "react"
import { Form, Segment, Icon, Button } from "semantic-ui-react";
import { Formik } from "formik";
import * as yup from "yup";
import { SelectFormikSemantic } from "../formHelper";
import { UserRole, emailPattern, userIdPattern } from "../../schema/userAssignment";
import { useRolesActions } from "../../context/dashboardStore";

const initialFormValues = { userId:'', userName:'', role:UserRole.MEMBER };

const roleOptions = [
    { key:UserRole.ADMIN, text:'Admin', value:UserRole.ADMIN },
    { key:UserRole.MEMBER, text:'Member', value:UserRole.MEMBER }
];

const AddRoleToUserForm = ({existingIds, existingUserNames}) => {
    const [ , { addNewRole }] = useRolesActions();

    const formValidationSchema = useMemo(() => {
        return yup.object().shape({
            userId:yup
                .string()
                .matches(userIdPattern, "Invalid userId (digits only, at least 3)")
                .notOneOf(existingIds || [], "This userId is already registered")
                .required("userId is required"),
            userName:yup
                .string()
                .matches(emailPattern, "Invalid email")
                .notOneOf(existingUserNames, "This email is alreadyt registered")
                .required("email is required")
        });
    }, [existingIds, existingUserNames]);

    const submitNewUser = (values, { setSubmitting, resetForm }) => {
        addNewRole(values)
            .then(r => {
                setSubmitting(false);
                resetForm();
            })
            .catch(e => {
                setSubmitting(false);
                resetForm();
                console.log(e);
            });
    };

    return (
        <Segment basic>
            <Formik initialValues={initialFormValues} validationSchema={formValidationSchema} onSubmit={submitNewUser}>
                {({ errors, dirty, values, handleChange, handleSubmit, isSubmitting }) => (
                    <Form>
                        <Form.Group>
                            <Form.Input name="userId" value={values.userId} onChange={handleChange} placeholder="User Id" error={errors.userId} />
                            <Form.Input name="userName" value={values.userName} onChange={handleChange} placeholder="Email" error={errors.userName} />
                            <SelectFormikSemantic name="role" value={values.role} options={roleOptions} onChange={handleChange} placeholder="Role" />
                            <Form.Field>
                                <Button type="submit" disabled={ Object.keys(errors).length>0 || !dirty || isSubmitting } onClick={handleSubmit}>Add</Button>
                                {isSubmitting ? <Icon loading name='spinner' /> : null}
                            </Form.Field>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        </Segment>
    );
};

export default AddRoleToUserForm;
