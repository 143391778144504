import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import qs from "qs";
import { withAuthService } from "../context/authProvider";
import PreAuthLoader from "../components/security/preAuthLoader";
import { PreAuthError } from "../components/security/preAuthMessage";

class Callback extends Component {
    constructor(props) {
        super(props);
        const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const ticket = queryParams.ticket;
        this.state = {
            isLoggedIn: props.authService.isLoggedIn(),
            ticket,
        }
    }

    componentDidMount() {
        if(!this.state.isLoggedIn && this.state.ticket) {

            this.props.authService.getBearerFromTicket(this.state.ticket)
                .then(bearer => {
                    this.setState({isLoggedIn: true, invalidTicket: false}, () => {
                        console.log(`CALLBACK / For debug: ${this.props.location.pathname}`);
                        this.props.authService.postLoginRedirect();
                    });
                })
                .catch(e => {
                    this.setState({ isLoggedIn: false, invalidTicket: true})
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // When using back-button of the browser we can go back to url than contains the SSO ticket,
        // so to avoid any errors coming from an old ticket we capture this event, and force a browser redirect
        if(!!prevState.ticket && prevState.isLoggedIn) {
            window.location.replace( `${this.props.location.pathname}`);
        }
    }

    render() {
        const { ticket, isLoggedIn, invalidTicket } = this.state;

        if(!isLoggedIn && !ticket) {
            // not authenticated => redirecting to Smile SSO login page,
            this.props.authService.login(true);
        }

        // Not authenticated, but I got a ticket : I'm waiting for the validation...
        if(!isLoggedIn && !!ticket) {
            if(!invalidTicket) {
                return <PreAuthLoader message="Getting things ready..." />
            } else {
                return <PreAuthError message="Invalid Ticket"/>
            }
        }

        // someone already logged in just hit /callback route with no parameters, so we redirect to /
        if(isLoggedIn && !ticket) {
            return <Redirect to="/" />
        }

        // someone already logged in just hit /callback route with some ticket as parameters (ie: browser history),
        // so we redirect to any available past requested page before login.
        if(isLoggedIn && !!ticket) {
            this.props.authService.postLoginRedirect();
        }

        return <PreAuthLoader message="Getting things ready..." />
    }
}

export default withAuthService(Callback);
