import React from "react";
import { Container } from "semantic-ui-react";
import NavBar from "./navBar";
const Layout = ({ children }) => (
    <>
        <NavBar />
        <Container>
            {children}
        </Container>
    </>
)
export default Layout
