import { teamClient } from "../services/graphqlClient";
import { TeamMembersQuery, TeamsQuery, UpdateTeamMutation } from "../schema/team";
import { RolesQuery, AssignRoleMutation, RemoveRoleMutation, UpdateRoleMutation } from "../schema/role";

// Team Members
export const fetchTeamMembers = (teamId) => async ({getState, setState}) => {
    const { teamMembers } = getState();
    if(teamMembers.fetching) return;
    if(!!teamId && !teamMembers.hasOwnProperty(teamId)) {
        setState({ teamMembers:{ ...teamMembers, fetching: true} });
        await teamClient.query(TeamMembersQuery, {teamId})
            .toPromise()
            .then(r => setState({
                teamMembers: {
                    data: {...teamMembers.data, [teamId]:r.data.TeamMembers}},
                    error: null,
                    fetching:false
            }))
            .catch(e => setState({
                teamMembers: {...teamMembers, fetching:false, error:e }
            }));
    }
};

// Roles
const refreshRoleState = async (setState) => {
    await teamClient.query(RolesQuery)
        .toPromise()
        .then(r => setState({roles:{ data:r.data.Roles, error:null, fetching: false} }))
        .catch(e => setState({roles:{ data:[], error:e, fetching: false}}));
};

export const fetchRoles = () => async ({getState, setState}) => {
    const { roles } = getState();
    if(roles.fetching) return;
    setState({roles:{...roles, fetching:true}});
    await refreshRoleState(setState);
};

export const addNewRole = (variables) => async ({setState}) => {
    await teamClient.mutation(AssignRoleMutation, variables).toPromise();
    await refreshRoleState(setState);
};

export const removeRole = (variables) => async ({setState}) => {
    await teamClient.mutation(RemoveRoleMutation, variables).toPromise();
    await refreshRoleState(setState);
};

export const updateRole = (variables) => async ({setState}) => {
    await teamClient.mutation(UpdateRoleMutation, variables).toPromise();
    await refreshRoleState(setState);
};

// Teams
const refreshTeamsState = async (setState) => {
    await teamClient.query(TeamsQuery)
        .toPromise()
        .then(r => setState({teams:{ data:r.data.Teams, error:null, fetching: false} }))
        .catch(e => setState({teams:{ data:[], error:e, fetching: false}}));
};

export const fetchTeams = () => async ({getState, setState}) => {
    const { teams } = getState();
    if(teams.fetching) return;
    setState({teams: {...teams, fetching:true}});
    await refreshTeamsState(setState);
};

export const updateTeam = (variables) => async ({setState}) => {
    await teamClient.mutation(UpdateTeamMutation, variables).toPromise();
    await refreshTeamsState(setState);
}

