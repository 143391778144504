import React from "react";
import { Message, Icon } from "semantic-ui-react";

const AppMessage = ({message, header, color, icon}) => {
    return (
            <Message color={color} icon>
                <Icon name={icon} />
                <Message.Content>
                    <Message.Header>{header}</Message.Header>
                    {message}
                </Message.Content>
            </Message>
    )
};

const AppLoader =({message}) => <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
        {message}
    </Message.Content>
</Message>

const AppInfo = ({message, header = "Information"}) => AppMessage({message, header, color:"blue", icon:"info"});
const AppWarning = ({message, header = "Warning"}) => AppMessage({message, header, color:"orange", icon:"warning"});
const AppError = ({message, header = "Error"}) => AppMessage({message, header, color:"red", icon:"times"});

export { AppInfo, AppWarning, AppError, AppLoader };