import React from "react"
import { useLocation } from "react-router-dom";
import Layout from "../components/layout";
import AppCrud from "../components/app/appCrud";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Home = ({ userTeams, user }) => {
    let query = useQuery();
    return (
        <Layout>
            <AppCrud userTeams={userTeams} bundleToDeploy={query.get("bundle")} />
        </Layout>);
}

export default Home;
