import React from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { useField } from "formik";

export const SelectFormikSemantic = (props) => {
    const [field, , helpers] = useField(props.name);
    return <Form.Select
            {...props}
            options={props.options}
            onChange={(e, v) => helpers.setValue(v.value)}
            value={field.value} />;
}

export const DropDownFormikSemantic = (props) => {
    const [ , , helpers] = useField(props.name);
    return <Dropdown
        {...props}
        options={props.options}
        onChange={(e, v) => helpers.setValue(v.value)}
    />;
}