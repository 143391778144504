import React, { useCallback, useState } from "react"
import { Container, Button, Label, Segment, Table, Icon, List, Confirm } from "semantic-ui-react";
import { TeamsAndRolesProvider, useTeamsActions } from "../../context/dashboardStore";
import Loader from "../loader";
import { AppError } from "../appMessage";
import EditTeamModal from "./editTeamModal";

const orderByTeamId = (t1, t2) => {
    if(t1.teamId < t2.teamId) return -1;
    if(t1.teamId > t2.teamId) return 1;
    return 0;
};

const TeamRow = React.memo(({team, onEdit}) => {
    const [deleting, setDeleting] = useState(false);
    const [confirmation, setConfirmation] = useState({
        show: false, message: "", onConfirm: () => {
        }
    });
    const [, {updateTeam}] = useTeamsActions();

    const onRemove = useCallback(() => {
        const action = () => {
            setConfirmation(prevState => ({...prevState, show: false}));
            setDeleting(true);
            return updateTeam({teamId: team.teamId, members: []});
        };
        return setConfirmation((prevState) => ({
            show: true,
            message: `Are you sure you wants to remove "${team.teamId}" ?`,
            onConfirm: action
        }));
    }, [team, updateTeam]);

    return (
        <Table.Row key={team.teamId}>
            <Table.Cell>
                {team.teamId}
            </Table.Cell>
            <Table.Cell>
                <List divided items={team.members.map(m => `${m.userName} (${m.userId})`)}/>
            </Table.Cell>
            <Table.Cell collapsing>
                <Button icon disabled={deleting} onClick={() => onEdit(team)}>
                    <Icon color="green" name='edit'/>
                </Button>
                <Button icon disabled={deleting} onClick={onRemove}>
                    <Icon color="red" name='delete'/>
                </Button>
                <Confirm
                    open={confirmation.show}
                    content={confirmation.message}
                    onConfirm={confirmation.onConfirm}
                    header='Please confirm'
                    cancelButton='Never mind'
                    confirmButton="Yes, let's do it"
                    onCancel={() => setConfirmation((prevState) => ({...prevState, show: false}))}
                />
            </Table.Cell>
        </Table.Row>
    );
});

const TeamCrud = ({teams, roles}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [teamToEdit, setTeamToEdit] = useState(null);

    const onEditTeam = useCallback((team) => {
        setTeamToEdit(team);
        setModalOpen(true);
    }, [setTeamToEdit]);

    const onAddNewTeam = useCallback(() => {
        setTeamToEdit(null);
        setModalOpen(true);
    }, [setModalOpen, setTeamToEdit]);

    const onCloseModal = useCallback(() => {
        setTeamToEdit(null);
        setModalOpen(false);
    }, [setModalOpen, setTeamToEdit]);

    if(teams.fetching || roles.fetching) return <Loader message="Retrieving Teams and Roles..."/>;
    if(teams.error || roles.error) return <AppError message="Something wrong happened while retrieving the teams"/>

    return (
        <Segment raised>
            <Container textAlign="right">
                <Button icon labelPosition="left" onClick={onAddNewTeam}>
                    <Icon name="users"/>
                    Add a new Team
                </Button>
            </Container>
            <EditTeamModal teams={teams.data || []} open={modalOpen} teamToEdit={teamToEdit} closeModal={onCloseModal} roles={roles.data || []} />
            <Label color="teal" ribbon>Existing teams</Label>
            <Table celled structured striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell singleLine>Team</Table.HeaderCell>
                        <Table.HeaderCell singleLine>Members</Table.HeaderCell>
                        <Table.HeaderCell singleLine>Edit/Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { teams.data.sort(orderByTeamId).map(team => <TeamRow onEdit={onEditTeam} key={team.teamId} team={team} />)}
                </Table.Body>
            </Table>
        </Segment>
    );
}

const TeamClientCrud = () => (
    <TeamsAndRolesProvider>
        {({teams, roles}) => <TeamCrud teams={teams} roles={roles} />}
    </TeamsAndRolesProvider>
);

export default TeamClientCrud;