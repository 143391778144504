import React from "react"
import Layout from "../components/layout";
import CostExplorer from "../components/cost/costExplorer";

const Cost = ({userRole, userTeams}) => {
    return (
        <Layout>
            <CostExplorer userRole={userRole} userTeams={userTeams} />
        </Layout>);
}

export default Cost;
