import { appClient } from "../services/graphqlClient";
import { AppsQuery, CreateAppMutation, DeleteAppMutation,
    StartAppMutation, StopAppMutation, UpdateAppLifeCycleMutation } from "../schema/app";
import {CACHE_FIRST} from "./cachePolicy";

const refreshAppsState = async (setState, policy= CACHE_FIRST) => {
    appClient.query(AppsQuery, {}, {...policy})
        .toPromise()
        .then(r => setState({apps:{ data:r.data.Apps, error:null, fetching: false} }))
        .catch(e => e => setState({apps:{ data:[], error:e, fetching: false}}));
};

export const fetchApps = (policy = CACHE_FIRST) => async ({getState, setState}) => {
    const { apps } = getState();
    if(apps.fetching) return;
    setState({apps:{...apps, fetching:true}});
    await refreshAppsState(setState, policy);
};

export const createApp = (variables) => async ({setState}) => {
    await appClient.mutation(CreateAppMutation, variables).toPromise();
    await refreshAppsState(setState);
};

export const startApp = (variables) => async ({setState}) => {
    await appClient.mutation(StartAppMutation, variables).toPromise();
    await refreshAppsState(setState);
};

export const stopApp = (variables) => async ({setState}) => {
    await appClient.mutation(StopAppMutation, variables).toPromise();
    await refreshAppsState(setState);
};

export const deleteApp = (variables) => async ({setState}) => {
    await appClient.mutation(DeleteAppMutation, variables).toPromise();
    await refreshAppsState(setState);
};

export const updateAppLifeCycle = (variables) => async ({setState}) => {
    await appClient.mutation(UpdateAppLifeCycleMutation, variables).toPromise();
    await refreshAppsState(setState);
};