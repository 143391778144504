import React, {useMemo, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Formik, useFormikContext } from "formik";
import {Container, Segment, Form} from "semantic-ui-react";
import { UserRole } from "../../schema/userAssignment";
import { AppCostsProvider, useTeamsStoreAndActions } from "../../context/dashboardStore";
import Loader from "../loader";
import {AppError} from "../appMessage";
import {SelectFormikSemantic} from "../formHelper";
import CostTable from "./costTable";

const CostLoader = React.memo(({teamId, ymTs}) => {
    return (
        <AppCostsProvider teamId={teamId} ymTs={ymTs}>
            {(teamCosts) => <CostTable teamCosts={teamCosts} ymTs={ymTs}/>}
        </AppCostsProvider>
    );
});

const CostSubmit = () => {
    const { values } = useFormikContext();
    const [ costTableParams, setCostTableParams ] = useState(null);
    useEffect(() => {
        setCostTableParams({
            teamId:values.teamId,
            ymTs:DateTime.fromISO(values.month, {zone:"utc"}).toSeconds()
        })
    }, [values]);
    if(!!costTableParams) {
        return <CostLoader teamId={costTableParams.teamId} ymTs={costTableParams.ymTs} />
    }
    return null;
}

const CostExplorer = ({userRole, userTeams}) => {
    const defaultDate = DateTime.utc();
    const [ teams , { fetchTeams }] = useTeamsStoreAndActions();

    const [initialValues, setInitialValues ] = useState({
        month:defaultDate.toFormat("yyyy-MM"),
        teamId:userTeams[0] || ''
    });

    const [teamOptions, setTeamOptions] = useState(userTeams.map(t => ({
        key:t,
        value:t,
        text:t
    })));

    useEffect(() => {
        if(userRole === UserRole.ADMIN) {
            fetchTeams();
        }
    }, [userRole, fetchTeams]);

    useEffect(() => {
        if(userRole === UserRole.ADMIN && !!teams.data) {
            setTeamOptions(teams.data.map(t => ({
                key:t.teamId,
                value:t.teamId,
                text:t.teamId
            })));
            setInitialValues((prevState) => ({...prevState, teamId:teams.data[0].teamId}))
        }
    }, [userRole, setInitialValues, setTeamOptions, teams]);

    const monthOptions = useMemo(() => {
        return Array.from({length:12}, (_, i) => {
            const dt = defaultDate.minus({months:i});
            const key = dt.toFormat("yyyy-MM");
            const text = dt.toFormat("MMMM yyyy");
            return {key, value:key, text};
        });
    }, [defaultDate]);

    if(userRole === UserRole.ADMIN) {
        if(teams.fetching) return <Loader message="Retrieving Teams..."/>;
        if(teams.error) return <AppError message="Something wrong happened while retrieving Teams"/>
    }

    return (
        <Segment raised>
            <Container>
                <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={() => {}}>
                    {({ errors, dirty, values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting }) => (
                        <div>
                            <Form>
                                <Form.Group widths='equal'>
                                    <SelectFormikSemantic label="Month" name="month" value={values.month} options={monthOptions} onChange={handleChange} placeholder="Month" error={!!errors.month} />
                                    <SelectFormikSemantic label="Team" name="teamId" value={values.teamId} options={teamOptions} onChange={handleChange} placeholder="Team" error={!!errors.teamId} />
                                </Form.Group>
                            </Form>
                            <CostSubmit />
                        </div>
                    )}
                </Formik>
            </Container>
        </Segment>
    )
}

export default CostExplorer
