class ApiException extends Error {
    constructor(error) {
        super();
        this.apiError = error;
    }
}

class ApiError extends ApiException {}

class UnauthorizedException extends ApiException {}

class PayloadException extends ApiException {}

export { ApiError, UnauthorizedException, PayloadException }