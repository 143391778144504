import React, {useMemo, useCallback, useState} from "react";
import { DateTime } from 'luxon';
import { Table, Label, Message, Icon, Popup } from "semantic-ui-react";
import { AppStatus, EndOfLifeAction } from "../../schema/app";
import ActionButtons from "./actionButtons";
import AppDetailModal from "./appDetailsModal";

const orderByTeamName = (r1, r2) => {
    if(r1 < r2) return -1;
    if(r1 > r2) return 1;
    return 0;
};

const orderByAppName = (r1, r2) => {
    if(r1.name < r2.name) return -1;
    if(r1.name > r2.name) return 1;
    return 0;
};

/*

<Popup
      trigger={<Icon circular name='heart' />}
      content='Hello. This is a mini popup'
      size='mini'
    />

 */

const AutomaticAction = ({app}) => {
    if(!!app.endOfLife && app.endOfLife.action !== EndOfLifeAction.DO_NOTHING) {
        const icon = <Icon color={app.endOfLife.action === EndOfLifeAction.TO_BE_DELETED ? 'red' : 'yellow'} name="clock outline"/>;
        const message = `This app will be ${app.endOfLife.action === EndOfLifeAction.TO_BE_DELETED ? 'deleted' : 'stopped'} on the ${DateTime.fromISO(app.endOfLife.expirationDate).toFormat('DD')}`;
        return <Popup trigger={icon} size='small' content={message} />
    }
    return null;
}

const QuotaInformation = ({isPending, isOverQuota}) => {

    if(isOverQuota) {
        return (
            <div style={{marginTop: "1em"}}>
                <Message  size="small" negative compact>You cannot create new app as you have reached the maximum of active apps</Message>
            </div>
        );
    }

    if(isPending) {
        return (
            <div style={{marginTop: "1em"}}>
                <Message  size="small" warning compact>An app is still creating, please wait for it to be ready, so you will be able to add others. Please click on refresh to get updates</Message>
            </div>
        );
    }

    return null;
};

const AppsByTeam = ({teamId, apps, quotaUsage}) => {
    const [appDetailModalState, setAppDetailModalState] = useState({open:false, app:null});

    const onCloseModal = useCallback(() => {
        setAppDetailModalState({open:false, app:null});
    }, []);

    const onOpenModal = useCallback((app) => {
        setAppDetailModalState({open:true, app});
    }, [])

    const {isOverQuota, isPending, activeApps, maxAllowedApp} = quotaUsage;
    const labelColor = isOverQuota ? "red" : (isPending ? "yellow" : "teal");

    return (
        <>
            <AppDetailModal open={appDetailModalState.open} teamId={teamId} app={appDetailModalState.app} onClose={onCloseModal}/>
            <Label color={labelColor} size="large" ribbon>{teamId} : {activeApps} / {maxAllowedApp}</Label>
            <QuotaInformation isPending={isPending} isOverQuota={isOverQuota} />
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Bundle</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Manage</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {apps.sort(orderByAppName).map(app =>
                        <Table.Row key={app.id} disabled={app.status === AppStatus.DELETED}>
                            <Table.Cell>
                                {app.status === AppStatus.RUNNING ? <a rel="noreferrer" target="_blank" href={`http://${app.parameters.hostname}`}>{app.name}</a> : app.name}
                            </Table.Cell>
                            <Table.Cell>{app.bundleName}</Table.Cell>
                            <Table.Cell
                                error={app.status === AppStatus.ERROR }
                                positive={app.status === AppStatus.RUNNING }
                                warning={app.status === AppStatus.CREATING || app.status === AppStatus.STARTING || app.status === AppStatus.STOPPING || app.status === AppStatus.STOPPED || app.status === AppStatus.DELETING}>
                                <AutomaticAction app={app}/>
                                {app.status}
                            </Table.Cell>
                            <Table.Cell>
                                <ActionButtons app={app} openAppDetailModal={onOpenModal} />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </>
    );
};

const AppTables = ({apps, quotaUsage, maxAllowedApp}) => {

    // Group apps by teamId
    const appsGroupedByTeam = useMemo(() => {
        return apps.reduce((byTeam, app) => {
            (byTeam[app['teamId']] = byTeam[app['teamId']] || []).push(app);
            return byTeam;
        }, {});
    }, [apps]);

    // return a table of apps by team, with quota usage
    return Object.keys(appsGroupedByTeam)
        .sort(orderByTeamName)
        .map(teamId => <AppsByTeam
            key={teamId}
            quotaUsage={{
                maxAllowedApp,
                activeApps: quotaUsage.usageByTeam[teamId] || 0,
                isPending: quotaUsage.pendingCreation.includes(teamId),
                isOverQuota: quotaUsage.overQuota.includes(teamId)
            }}
            teamId={teamId}
            apps={appsGroupedByTeam[teamId]}
        />);
}

export default AppTables;