import { catalogClient } from "../services/graphqlClient";
import { CatalogQuery } from "../schema/catalog";
import { CACHE_FIRST } from "./cachePolicy";

const withKeywordsIndex = (data) => {
    const index = data.reduce((keywordPointer, item) => {
        const { id, keywords } = item;
        keywords.forEach(k => !!keywordPointer[k] ?
            keywordPointer[k].add(id) : keywordPointer[k] = new Set([id]));
        return keywordPointer;
    }, {});

    return {
        items:data,
        index
    }
}

export const fetchCatalog = (policy = CACHE_FIRST) => async ({ getState, setState }) => {
    const { catalog } = getState();
    if (catalog.fetching) return;

    setState({ catalog:{ ...catalog, fetching: true} });
    await catalogClient.query(CatalogQuery, {}, {...policy})
        .toPromise()
        .then(r => setState({ catalog:{ data:withKeywordsIndex(r.data.getCatalog), error:null, fetching: false } }))
        .catch(e => setState({ catalog:{ data:[], error:e, fetching: false} }));
};