import React, { useContext, useState } from "react"
import { NavLink } from "react-router-dom";
import { Menu, Container, Icon, Image } from "semantic-ui-react";
import AuthContext from "../context/authContext";
import {UserRole} from "../schema/userAssignment";
import Logo from "../assets/smile.png";

const NavBar = () => {
    const {authService} = useContext(AuthContext);
    const { role } = authService.getUserAssignment()
    const [ activeItem, setActiveItem ] = useState("");
    return (
        <>
            {authService.isLoggedIn() ? (
                <Menu>
                    <Container>
                        <Menu.Item>
                            <Image src={Logo} size='mini' />
                        </Menu.Item>
                        <Menu.Item name="Dashboard" as={NavLink} exact to="/" active={activeItem === "home"} onClick={() => setActiveItem("home")} >
                            <Icon name="clipboard outline" />
                            Dashboard
                        </Menu.Item>
                        <Menu.Item name="Catalog" as={NavLink} exact to="/catalog" active={activeItem === "catalog"} onClick={() => setActiveItem("catalog")} >
                            <Icon name="book" />
                            Catalog
                        </Menu.Item>
                        <Menu.Item name="Cost" as={NavLink} exact to="/cost" active={activeItem === "cost"} onClick={() => setActiveItem("cost")} >
                            <Icon name="dollar sign" />
                            Cost
                        </Menu.Item>
                        { role === UserRole.ADMIN ?
                        <Menu.Item name="Team" as={NavLink} exact to="/team" active={activeItem === "team"} onClick={() => setActiveItem("team")} >
                            <Icon name="users" />
                            Team Management
                        </Menu.Item>
                        : null }
                        <Menu.Item position='right' name="Logout" onClick={event => {
                            event.preventDefault()
                            authService.logout();
                        }} >
                            <Icon name="sign-out" />
                            Logout
                        </Menu.Item>
                    </Container>
                </Menu>
            ) : null }
        </>
    )
}

export default NavBar;
