import { createClient, dedupExchange, fetchExchange } from "urql";
import { cacheExchange } from '@urql/exchange-graphcache';
import { makeOperation } from '@urql/core';
import { authExchange } from "@urql/exchange-auth";
import { DateTime } from "luxon";
import AppBearer from "./appBearer";
import { forceReLogin } from "../context/authProvider";
import { RolesQuery } from "../schema/role";
import { TeamsQuery} from "../schema/team";
import { AppsQuery, AppStatus } from "../schema/app";
import config from '../config';

// URLQ authExchange configuration :
// getAuth method retrieve the current app bearer, to be then included as Authorization header
// in each GraphQL request using this client
// Exchange documentation :
// - https://github.com/FormidableLabs/urql/tree/main/exchanges/auth#quick-start-guide
// - https://formidable.com/open-source/urql/docs/api/auth-exchange/
const authConfig = {
    addAuthToOperation: ({authState, operation,}) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState || !authState.bearer) {
            return operation;
        }

        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {};

        return makeOperation(
            operation.kind,
            operation,
            {
                ...operation.context,
                fetchOptions: {
                    ...fetchOptions,
                    headers: {
                        ...fetchOptions.headers,
                        "Authorization": `bearer ${authState.bearer}`,
                    },
                },
            },
        );
    },
    willAuthError({authState}) {
        const isExpired = !!authState.expireAt ? (authState.expireAt < DateTime.utc()) : true;
        if (!authState || isExpired) return true;
        // e.g. check for expiration, existence of auth etc
        return false;
    },

    didAuthError: ({ error }) => {
        // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
        return error.graphQLErrors.some(e => e.extensions?.code === 'UNAUTHENTICATED');
    },

    getAuth:({authState, mutate}) => {
        if(!authState) {
            return {bearer:AppBearer.getBearer(), expireAt:AppBearer.expirationDate()};
        }

        // Reset auth state of the application
        forceReLogin();
        return Promise.resolve(null); //return promise to avoid strange bug
    }

};

// -----------------------------------------------------------------------
//TODO : For all URLQ Clients :
// - factorize, centralize and cache update methods of urlq client !!!
// - investigate https://formidable.com/open-source/urql/docs/advanced/auto-populate-mutations/
// -----------------------------------------------------------------------

export const catalogClient = createClient({
    url:config.CATALOG_CLIENT_URL,
    exchanges: [
        dedupExchange,
        cacheExchange({
            keys:{
                CatalogItem:data => data.id
            },
            resolvers: {
                CatalogItem: {
                    keywords:(parent, args, cache, info) => {
                        const splittedKeyworks = parent.keywords.reduce((kws, kw) => {
                            const tokens = kw.split('-');
                            const mergedTokens = kws.concat(tokens);
                            return mergedTokens
                        }, []);
                        if(!!parent.appVersion) splittedKeyworks.push(parent.appVersion);
                        return splittedKeyworks;
                    }
                }
            }
        }),
        authExchange(authConfig),
        fetchExchange
    ]
});

export const appCostsClient = createClient({
    url:config.COST_CLIENT_URL,
    exchanges: [
        dedupExchange,
        cacheExchange({
            keys:{
                AppCost:data => `${data.month}#${data.appId}`
            }
        }),
        authExchange(authConfig),
        fetchExchange
    ]
});

export const teamClient = createClient({
    url:config.TEAM_CLIENT_URL,
    exchanges: [
        dedupExchange,
        cacheExchange({
            keys:{
                UserAssignment: data => data.userId,
                TeamMembers: data => data.userId,
                User: data => data.userId,
                Role: data => data.userId,
                Team: data => data.teamId
            },
            updates:{
                Mutation:{
                    assignRole:(payload, _args, cache, info) => {
                        cache.updateQuery({query: RolesQuery}, data => {
                            if(data !== null && data.Roles !== null) {
                                data.Roles.unshift(_args);
                            }
                            return data;
                        });
                    },
                    updateRole:(payload, _args, cache, info) => {
                        cache.updateQuery({query: RolesQuery}, data => {
                            if(data !== null && data.Roles !== null) {
                                const existingRole = data.Roles
                                    .find((r) => r.userId === _args.userId);
                                if(existingRole) {
                                    existingRole.role = _args.role;
                                }
                            }
                            return data;
                        });
                    },
                    removeUserFromRoles:(payload, _args, cache, info) => {
                        cache.updateQuery({query: RolesQuery}, data => {
                            if(data !== null && data.Roles !== null) {
                                const itemIndex = data.Roles
                                    .findIndex(r => r.userId === _args.userId);
                                if(itemIndex > -1) {
                                    data.Roles.splice(itemIndex, 1);
                                }
                            }
                            return data;
                        });
                    },
                    updateTeam:(payload, _args, cache, info) => {
                        cache.updateQuery({query:TeamsQuery}, data => {
                            if(data !== null && data.Teams !== null) {
                                const { teamId, members } = _args;
                                const teamIdx = data.Teams.findIndex(t => t.teamId === teamId);
                                if(members.length > 0) { // Team update
                                    if(teamIdx > -1) {
                                        data.Teams[teamIdx].members = members;
                                    } else {
                                        data.Teams.unshift({teamId, members});
                                    }
                                } else { // Team removal
                                    if(teamIdx > -1) {
                                        data.Teams.splice(teamIdx, 1);
                                    }
                                }
                            }
                            return data;
                        });
                    }
                }
            }
        }),
        authExchange(authConfig),
        fetchExchange
    ]
});

export const appSshTokenClient = createClient({
    url:config.APP_CLIENT_URL,
    exchanges:[
        authExchange(authConfig),
        fetchExchange
    ]
})

export const appClient = createClient({
    url:config.APP_CLIENT_URL,
    exchanges:[
        dedupExchange,
        cacheExchange({
            keys:{
                App: data => data.id,
                Parameters: data => data.cfStackName,
                AppEvent: data => data.updatedAt,
                EndOfLife: () => null
            },
            updates:{
                Mutation:{
                    createApp:(payload, _args, cache, info) => {
                        cache.updateQuery({query: AppsQuery}, data => {
                            const newApp = payload.createApp.app;
                            if(data !== null && data.Apps !== null) {
                                data.Apps.unshift(newApp);
                            }
                            return data;
                        });
                    },
                    //TODO: use payload response field newStatus !!!
                    startApp:(payload, _args, cache, info) => {
                        cache.updateQuery({query: AppsQuery}, data => {
                            if(data !== null && data.Apps !== null) {
                                const app = data.Apps.find(a => a.id === _args.id);
                                if(!!app) app.status = AppStatus.STARTING;
                            }
                            return data;
                        });
                    },
                    //TODO: use payload response field newStatus !!!
                    stopApp:(payload, _args, cache, info) => {
                        cache.updateQuery({query: AppsQuery}, data => {
                            if(data !== null && data.Apps !== null) {
                                const app = data.Apps.find(a => a.id === _args.id);
                                if(!!app) app.status = AppStatus.STOPPING;
                            }
                            return data;
                        });
                    },
                    //TODO: use payload response field newStatus !!!
                    deleteApp:(payload, _args, cache, info) => {
                        cache.updateQuery({query: AppsQuery}, data => {
                            if(data !== null && data.Apps !== null) {
                                const app = data.Apps.find(a => a.id === _args.id);
                                if(!!app) app.status = AppStatus.DELETING;
                            }
                            return data;
                        });
                    },
                    updateAppLifeCycle:(payload, _args, cache, info) => {
                        cache.updateQuery({query: AppsQuery}, data => {
                            if(data !== null && data.Apps !== null) {
                                const app = data.Apps.find(a => a.id === _args.id);
                                if(!!app) {
                                    app.endOfLife = {
                                        action:_args.action,
                                        expirationDate:_args.expirationDate,
                                        __typename: "EndOfLife"
                                    }
                                }
                            }
                            return data;
                        });
                    }
                }
            }
        }),
        authExchange(authConfig),
        fetchExchange
    ]
});
