import React, { useMemo } from "react";
import { Formik } from "formik";
import { Form } from "semantic-ui-react";
import { DateTime } from "luxon";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useAppsActions } from "../../context/dashboardStore";
import { SelectFormikSemantic } from "../formHelper";
import { EndOfLifeAction } from '../../schema/app';

const actionOptions = [
    {key:EndOfLifeAction.TO_BE_STOPPED, value:EndOfLifeAction.TO_BE_STOPPED, text:'Stop it'},
    {key:EndOfLifeAction.TO_BE_DELETED, value:EndOfLifeAction.TO_BE_DELETED, text:'Delete it'}
];

const minDate = DateTime.local().plus({day:1}).toJSDate();
const defaultValues = {
    enabled:false,
    expirationDate:minDate,
    minDate:minDate,
    action:EndOfLifeAction.TO_BE_STOPPED
}

const AppLifeCycle = ({app}) => {

    const [, { updateAppLifeCycle }] = useAppsActions();

    const appValues = useMemo(() => {
        if(!!app.endOfLife) {
            return {
                enabled: app.endOfLife.action !== EndOfLifeAction.DO_NOTHING,
                action: app.endOfLife.action !== EndOfLifeAction.DO_NOTHING ? app.endOfLife.action : defaultValues.action,
                expirationDate: app.endOfLife.action === EndOfLifeAction.DO_NOTHING ? minDate : DateTime.fromISO(app.endOfLife.expirationDate).toJSDate(),
                minDate: defaultValues.minDate
            };
        } else {
            return { ...defaultValues };
        }
    }, [app]);

    let formValues = { ...appValues };

    const updateLifeCycle = (values, { setSubmitting }) => {
        const { enabled, action, expirationDate } = values;
        updateAppLifeCycle({
            id: app.id,
            action: enabled ? action : EndOfLifeAction.DO_NOTHING,
            expirationDate: DateTime.fromJSDate(expirationDate).toISODate()
        }).then(_ => {
            formValues.enabled = enabled;
            formValues.action = enabled ? action : EndOfLifeAction.DO_NOTHING;
            formValues.expirationDate = expirationDate;
            setSubmitting(false);
        });
    };

    return (
        <Formik initialValues={formValues} enableReinitialize={true} onSubmit={updateLifeCycle}  >
            {({ errors, dirty, values, handleChange, handleBlur, setFieldValue, handleSubmit, handleReset, isSubmitting }) => (
                <Form loading={isSubmitting}>
                    <Form.Checkbox name="enabled" checked={values.enabled} label="Enable automatic action" onChange={() => setFieldValue('enabled', !values.enabled)} onBlur={handleBlur} error={errors.enabled} />
                    <Form.Group widths='equal'>
                        <SelectFormikSemantic label="Automatic action" disabled={!values.enabled} name="action" value={values.action} options={actionOptions} onChange={handleChange} placeholder="Automatic action" error={!!errors.action} />
                        <SemanticDatepicker
                            label="Apply action at"
                            disabled={!values.enabled}
                            readOnly={!values.enabled}
                            clearable={!values.enabled}
                            name="expirationDate"
                            value={values.expirationDate}
                            minDate={values.minDate}
                            onChange={(e, data) => setFieldValue("expirationDate", data.value)}
                            format="DD-MM-YYYY"
                            error={!!errors.expirationDate}
                        />
                    </Form.Group>
                    <Form.Group >
                        <Form.Button disabled={!dirty || isSubmitting} onClick={handleReset}>Cancel</Form.Button>
                        <Form.Button primary disabled={!dirty || isSubmitting} onClick={handleSubmit}>Save</Form.Button>
                    </Form.Group>
                </Form>
            )}
        </Formik>
    );
}

export default AppLifeCycle;