import React from "react";
import { Grid, Container, Message, Icon } from "semantic-ui-react";

const PreAuthMessage = ({message, header, color, icon}) => {
    return (
        <Grid style={{height:"100vh", width:"100%"}} verticalAlign='middle' columns={1} centered>
            <Grid.Row>
                <Grid.Column>
                    <Container>
                        <Message color={color} icon>
                            <Icon name={icon} />
                            <Message.Content>
                                <Message.Header>{header}</Message.Header>
                                {message}
                            </Message.Content>
                        </Message>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

const WelcomeBox = ({children, header = "Information", color = "grey", icon = "hand peace"}) => {
    return (
        <Grid style={{height:"100vh", width:"100%"}} verticalAlign='middle' columns={1} centered>
            <Grid.Row>
                <Grid.Column>
                    <Container>
                        <Message color={color} icon>
                            <Icon name={icon} />
                            <Message.Content>
                                <Message.Header>{header}</Message.Header>
                                {children}
                            </Message.Content>
                        </Message>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

const PreAuthInfo = ({message, header = "Information"}) => PreAuthMessage({message, header, color:"blue", icon:"info"});
const PreAuthWarning = ({message, header = "Warning"}) => PreAuthMessage({message, header, color:"orange", icon:"warning"});
const PreAuthError = ({message, header = "Error"}) => PreAuthMessage({message, header, color:"red", icon:"times"});

export { PreAuthInfo, PreAuthWarning, PreAuthError, WelcomeBox};