import React, { useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal, Button, Container, Form } from "semantic-ui-react";
import { teamNamingConvention } from "../../schema/team";
import { DropDownFormikSemantic } from "../formHelper";
import {useTeamsActions} from "../../context/dashboardStore";

const newTeamInitialValue = {teamId:"", members:[]};

const EditTeamModal = ({teams, roles, open, closeModal, teamToEdit}) => {
    const [, { updateTeam }] = useTeamsActions();

    const initialValues = useMemo(() => {
        if(teamToEdit) {
            return {teamId: teamToEdit.teamId, members:teamToEdit.members.map(m => m.userId)};
        }
        return newTeamInitialValue;
    }, [teamToEdit]);

    const newTeamFormValidationSchema = useMemo(() => {
        const teamsIds = teams.map(t => t.teamId.toLocaleLowerCase());
        return yup.object().shape({
            teamId:yup
                .string()
                .matches(teamNamingConvention, "Invalid team name (min 3, max 30, alphanumerical, - , no space)")
                .lowercase()
                .notOneOf(teamsIds || [], "This team name is already used")
                .required("team name is required"),
            members:yup.array().of(yup.string()).min(1)
                .required("members are required")
        });
    }, [teams]);

    const editTeamFormValidationSchema = useMemo(() => {
        const teamsIds = teams.map(t => t.teamId.toLocaleLowerCase());
        return yup.object().shape({
            teamId:yup
                .string()
                .matches(teamNamingConvention, "Invalid team name (min 3, max 30, alphanumerical, - , no space)")
                .lowercase()
                .oneOf(teamsIds || [], "You cannot change the team name")
                .required("team name is required"),
            members:yup.array().of(yup.string()).min(1)
                .required("members are required")
        });
    }, [teams]);

    const membersOptions = useMemo(() => {
        return roles.map(r => ({
            key:r.userId,
            value:r.userId,
            text:`${r.userName} (${r.userId})`
        }));
    }, [roles]);

    const submitNewTeam = (values, { setSubmitting, resetForm }) => {
        // Dropdown options can only contains string values, so we have to transform the value (userId)
        // into an user object (userId, userName) from the user roles.
        const members = values.members.reduce((validMembers, userId) => {
            const existingRole = roles.find(m => m.userId === userId);
            if(!!existingRole) {
                validMembers.push({userId, userName:existingRole.userName});
            }
            return validMembers;
        }, []);

        updateTeam({teamId:values.teamId, members})
            .then(r => {
                setSubmitting(false);
                resetForm();
                closeModal();
            });
    };

    return (
        <Container>
            <Modal size="large" onClose={closeModal} open={open} >
                <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={teamToEdit ? editTeamFormValidationSchema : newTeamFormValidationSchema} onSubmit={submitNewTeam} onReset={closeModal}>
                    {({ errors, dirty, values, handleChange, handleSubmit, handleReset, isSubmitting }) => (
                        <>
                            <Modal.Header>{teamToEdit ? `Edit ${values.teamId}` : "New Team"}</Modal.Header>
                            <Modal.Content>
                            <Form loading={isSubmitting}>
                                <Form.Input width={6} disabled={!!teamToEdit} error={errors.teamId} label="Team name" name="teamId" value={values.teamId} onChange={handleChange}/>
                                <Form.Field>
                                    <label>Team members</label>
                                    <DropDownFormikSemantic
                                        name="members"
                                        fluid
                                        placeholder="Members"
                                        clearable
                                        multiple
                                        search
                                        error={!!errors.members}
                                        selection
                                        defaultValue={teamToEdit ? values.members : []}
                                        options={membersOptions}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="grey" disabled={isSubmitting} onClick={handleReset}>Cancel</Button>
                                <Button type="submit" disabled={ Object.keys(errors).length>0 || !dirty || isSubmitting} color="blue" onClick={handleSubmit}>Save</Button>
                            </Modal.Actions>
                        </>
                    )}
                </Formik>
            </Modal>
        </Container>
    );
}

export default EditTeamModal;
