import React from "react";
import { Message, Icon, Placeholder } from "semantic-ui-react";

const Loader = ({message}) => {
    return (
        <>
            <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                    {message}
                </Message.Content>
            </Message>
            <Placeholder fluid>
                <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>
        </>
    );
}

export default Loader;