import React, { useEffect, useState, useRef } from "react";
import { XTerm } from 'xterm-for-react'
import { FitAddon } from "xterm-addon-fit";
import { Modal, Button, Icon, Divider } from "semantic-ui-react";
import { getAppSshToken } from "../../context/appSshTokenAction";
import createSSHWebSocket, { SShActions } from "../../services/sshWebsocket";

// https://en.wikipedia.org/wiki/ANSI_escape_code
const yellow = (txt) => `\u001b[33m${txt}\u001b[39m`;
const red = (txt) => `\u001b[31m${txt}\u001b[39m`;
const green = (txt) => `\u001b[32m${txt}\u001b[39m`;

const sshWrite = (action, payload) => ({
    data: {action, payload}});

const sshIn = (payload) => sshWrite(SShActions.In, payload);

const fitAddon = new FitAddon();

class WsAddon {
    constructor(ws) {
        this._disposables = [];
        this._ws = ws;
        this.sessionClose = false;
    }

    activate(terminal) {
        const onReceive = () => {

            this._ws.onClose.addListener(e => {
                this.sessionClose = true;
                terminal.writeln(red("#### SSH Connection closed #####"));
            });

            this._ws.onUnpackedMessage.addListener(msg => {
                const { action, payload } = msg.data;
                switch(action) {
                    case SShActions.AuthOk:
                        terminal.writeln(green("#### Authorization validated #####"));
                        break;
                    case SShActions.AuthKo:
                        terminal.writeln(yellow("#### Authorization invalid. Please logout/login from the web app, and retry #####"));
                        break;
                    case SShActions.Out:
                        terminal.write(payload)
                        break;
                    case SShActions.Err:
                        terminal.write(yellow(payload));
                        break;
                    default:
                        break;
                }
            })
            return {
                dispose: () => {
                    this._ws.removeAllListeners();
                }
            }
        }

        const sendData = (input) => {
            if(this._ws.isOpened && !this.sessionClose) {
                const msg = sshIn(input);
                this._ws.sendPacked(msg);
            }
        }

        const onError = () => {
            this._ws.onError.addListener(event => terminal.write(`#### Oops, the SSH connection is broken! : ${event} ####`));
            return {
                dispose: () => {
                    this._ws.removeAllListeners();
                }
            }
        }

        this._disposables.push(onReceive());
        this._disposables.push(terminal.onData(sendData));
        this._disposables.push(onError());
    }

    dispose() {
        this._disposables.forEach(d => d.dispose());
        this._disposables.length = 0;
    }
}

const Terminal = ({ws, wsError}) => {
    const xtermRef = useRef(null);

    useEffect(() => {
        if(ws && !wsError && xtermRef.current) {
            xtermRef.current.terminal.focus();
        }
    }, [ws, wsError]);

    if(wsError) {
        return <div><h4>An error occurred while connecting. Please close this window and retry.</h4></div>
    }

    if(ws && ws.isOpened) {
        const wsAddon = new WsAddon(ws);
        return <XTerm ref={xtermRef} addons={[fitAddon, wsAddon]} />
    } else {
        return <div><Icon name="sync" loading={true} />Connecting...</div>;
    }
}

const SshConsole = ({onClose, open, app}) => {
    const [ws, setWs] = useState(null);
    const [wsError, setWsError] = useState(null);

    // Mount => connect WS
    useEffect(() => {

        (async function openSSHWebsocket() {
            if(open) {
                const token = await getAppSshToken(app.id);
                await createSSHWebSocket(token)
                    .then(_ws => {
                        setWs(_ws);
                        setWsError(null);
                    }).catch(e => {
                        console.log(e);
                        setWsError(e);
                    });
            }
        })();

    }, [open, app]);

    // Umount => disconnect WS
    useEffect(() => {
        return () => {
            if(open && ws && ws.isOpened) {
                ws.close();
            }
        };
    }, [open, ws]);

    return (
        <Modal closeOnEscape={false} closeOnDimmerClick={false} onClose={onClose} open={open}>
            <Modal.Header>SSH on host {app.parameters.hostname}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <h3><Icon color="red" name="warning sign"/>Use this console at your own risk !</h3>
                    <p>Idle connections are automatically close after 4 mins</p>
                    <Divider/>
                </Modal.Description>
                <Terminal ws={ws} wsError={wsError}/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose()}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SshConsole;