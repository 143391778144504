import React from "react";
import { Grid, Container, Message, Icon } from "semantic-ui-react";

const PreAuthLoader = ({message}) => {
    return (
        <Grid style={{height:"100vh", width:"100%"}} verticalAlign='middle' columns={1} centered>
            <Grid.Row>
                <Grid.Column>
                    <Container>
                        <Message icon>
                            <Icon name="circle notched" loading />
                            <Message.Content>
                                <Message.Header>Just one second</Message.Header>
                                {message}
                            </Message.Content>
                        </Message>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default PreAuthLoader;