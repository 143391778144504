import React  from "react"
import { withAuthService } from "../../context/authProvider";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const authService = rest.authService;
    if(authService.isLoggedIn()) {
        const user = authService.getUser();
        const userAssignment = authService.getUserAssignment();
        return <Component {...rest} user={user} userRole={userAssignment?.role || "NONE"} userTeams={userAssignment.teams || []}/>
    } else {
        authService.login(true);
        return null;
    }
}

export default withAuthService(PrivateRoute);
