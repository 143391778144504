import React, { useState, useCallback } from "react";
import { useAppsActions } from "../../context/dashboardStore";
import { AppStatus } from "../../schema/app";
import {Button, Confirm, Icon} from "semantic-ui-react";

const ActionButtons = ({app, openAppDetailModal}) => {
    const { id, status } = app;
    const [confirmation, setConfirmation] = useState({show:false, message:"", onConfirm:() => {}});
    const [editing, setEditing] = useState(false);
    const [, { startApp, stopApp, deleteApp }] = useAppsActions();

    const { CREATING, RUNNING, STOPPED, DELETING, DELETED} = AppStatus;

    const onDetailApp = useCallback(() => {
        return openAppDetailModal(app);
    }, [app, openAppDetailModal])

    const onRestartApp = useCallback(() => {
        setEditing(true);
        return startApp({id}).then(_ => setEditing(false));
    }, [id, startApp, setEditing]);

    const onStopApp = useCallback(() => {
        setEditing(true);
        return stopApp({id}).then(_ => setEditing(false));
    }, [id, stopApp]);

    const onDeleteApp = useCallback(() => {
        const action = () => {
            setConfirmation(prevState => ({...prevState, show:false}));
            setEditing(true);
            return deleteApp({id}).then(_ => setEditing(false));
        };
        return setConfirmation((prevState) => ({
            show: true,
            message:`Are you sure you wants to delete the "${app.name}" app ?`,
            onConfirm: action
        }))
    }, [id, deleteApp, app]);

    const endOfApp = status === DELETED || status === DELETING;

    const detailDisabled = editing || endOfApp;
    const startDisabled = editing || endOfApp || status !== STOPPED;
    const stopDisabled = editing || endOfApp || status !== RUNNING;
    const deleteDisabled = editing || endOfApp || status === CREATING;

    return (
        <>
            <Confirm
                open={confirmation.show}
                content={confirmation.message}
                onConfirm={confirmation.onConfirm}
                header='Please confirm'
                cancelButton='Never mind'
                confirmButton="Yes, let's do it"
                onCancel={() => setConfirmation((prevState) => ({...prevState, show:false}))}
            />
            <Button icon disabled={detailDisabled} onClick={onDetailApp}>
                <Icon name='info' />
            </Button>
            <Button.Group icon>
                <Button icon disabled={startDisabled} onClick={onRestartApp}>
                    <Icon name='play' />
                </Button>
                <Button icon disabled={stopDisabled} onClick={onStopApp} >
                    <Icon name='stop' />
                </Button>
                <Button icon disabled={deleteDisabled} onClick={onDeleteApp}>
                    <Icon name='trash' />
                </Button>
            </Button.Group>

        </>
    )
}

export default React.memo(ActionButtons);
