import React from 'react';

const AuthContext = React.createContext({
    authService: {
        isLoggedIn: () => false,
        getBearerFromTicket: (ticket, origin) => {},
        getRoleFromCurrentUser: () => {},
        getUser: () => {},
        getUserAssignment: () => {},
        login: (redirecToOrigin) => {},
        postLoginRedirect: () => {},
        logout: () => {}
    }
});

AuthContext.displayName = "AuthContext";

export default AuthContext;