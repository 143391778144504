import { DateTime } from "luxon";
import { AppStatus } from "../../schema/app";

//TODO : BUG when app is "stopping", when "running" or "stopped" estimation is ok
const uptimeEstimation = (auditLog) => {
    const lastIdx = auditLog.length - 1;
    const computation = auditLog.reduce((total, event, idx) => {
        const isLast = idx === lastIdx;
        let uptime = 0;
        let ts = null;
        switch (event.status) {
            case AppStatus.RUNNING:
                if(isLast) {
                    ts = DateTime.fromISO(event.updatedAt);
                    uptime = DateTime.utc().diff(ts, 'minutes').minutes + total.uptime;
                    return {...total, uptime}
                } else {
                    ts = DateTime.fromISO(event.updatedAt);
                    return {...total, previousRun:ts }
                }
            case AppStatus.STOPPED:
            case AppStatus.DELETED:
                ts = DateTime.fromISO(event.updatedAt);
                uptime = ts.diff(total.previousRun, 'minutes').minutes + total.uptime;
                return {...total, uptime };
            default:
                break;
        }
        return total;
    }, {uptime: 0, previousRun:null});
    return computation.uptime;
}

export default uptimeEstimation;