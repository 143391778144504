import React from "react"
import { Tab } from "semantic-ui-react";
import Layout from "../components/layout";
import TeamCrud from "../components/teamManagement/teamCrud";
import RoleCrud from "../components/roleManagement/roleCrud";

const panes = [
    { menuItem: 'Roles', render: () => <RoleCrud /> },
    { menuItem: 'Teams', render: () => <TeamCrud /> },
];

const TeamManagement = () => {
    return (
        <Layout>
            <Tab panes={panes}/>
        </Layout>);
}

export default TeamManagement;
