import React, { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Icon, Segment } from "semantic-ui-react";
import { AppsProvider, useAppsActions } from "../../context/dashboardStore";
import { AppStatus } from "../../schema/app";
import NewAppModalFormWithCatalog from "./newAppModalForm";
import AppTables from "./appTables";
import Loader from "../loader";
import {AppError, AppInfo, AppWarning} from "../appMessage";

const MAX_ENABLED_APP = 10;

const AppCrud = ({apps, userTeams, bundleToDeploy}) => {
    const [, { reloadApps } ] = useAppsActions();
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(false);

    const onAddNewApp = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);

    const onCloseModal = useCallback(() => {
        if(!!bundleToDeploy) {
            history.replace("/");
        } else {
            setModalOpen(false);
        }
    }, [setModalOpen, bundleToDeploy, history]);

    /*
    Return a object with 4 attributes :
        - pendingCreation : list of teamId containing at least one app in pending creation
        - overQuota : list of teamId having reach the limit of active apps
        - usageByTeam : object with number of active apps per team
        - allowedTeam : list of teamId neither having pending creating or having reached limit of active apps
     */
    const quotaUsage = useMemo(() => {
        const appsDefinition = apps.data || [];
        if(apps.fetching || apps.error) return { pendingCreation:[], overQuota:[], usageByTeam:{}, allowedTeam:[]};

        const pendingCreation = [...appsDefinition.reduce((disabledTeams, app) => {

            if(app.status === AppStatus.CREATING) {
                disabledTeams.add(app.teamId);
            }
            return disabledTeams;
        }, new Set())];

        const usageByTeam = appsDefinition.reduce((usage, app) => {
            if(app.status !== AppStatus.DELETING && app.status !== AppStatus.DELETED) {
                usage[app.teamId] = (usage[app.teamId] || 0) + 1;
            }
            return usage;
        }, {});

        const overQuota = Object.keys(usageByTeam).filter(teamId => usageByTeam[teamId] >= MAX_ENABLED_APP);
        const allowedTeam = userTeams.filter(team => !overQuota.includes(team) && !pendingCreation.includes(team));

        return { pendingCreation, overQuota, usageByTeam, allowedTeam};
    }, [apps, userTeams]);

    if(userTeams.length === 0) return <AppWarning message="You do not belong to any teams, so you cannot create an app. Please contact the application admin to be assigned to at least one team" />
    if(apps.fetching) return <Loader message="Retrieving Apps..."/>;
    if(apps.error) return <AppError message="Something wrong happened while retrieving the Apps" />

    let deployBundle = false;
    if(quotaUsage.allowedTeam.length > 0 && !!bundleToDeploy) {
        deployBundle = true;
    }

    return (
        <Segment raised>
            <Container textAlign="right">
                <Button disabled={quotaUsage.allowedTeam.length === 0} icon labelPosition="left" onClick={onAddNewApp}>
                    <Icon name="plus"/>
                    Add a new app
                </Button>
                <Button icon labelPosition="left" onClick={reloadApps}>
                    <Icon name="refresh" />
                    Refresh apps
                </Button>
            </Container>
            <NewAppModalFormWithCatalog bundleToDeploy={bundleToDeploy} open={modalOpen || deployBundle} closeModal={onCloseModal} userTeams={quotaUsage.allowedTeam} />
            <Container>
                {apps.data.length === 0 ?
                    <Segment basic><AppInfo message="You do not have any apps so far"/></Segment> :
                    <AppTables maxAllowedApp={MAX_ENABLED_APP} quotaUsage={quotaUsage} apps={apps.data}/>
                }
            </Container>
        </Segment>
    );
};

const AppClientCrud = (props) => (
    <AppsProvider>
        { (apps) => <AppCrud {...props} apps={apps} /> }
    </AppsProvider>
);

export default AppClientCrud;
