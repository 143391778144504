import React from "react";
import { Table, Label, Segment } from "semantic-ui-react";
import Loader from "../loader";
import { AppError } from "../appMessage";
import RoleRow from "./roleRow";
import AddRoleToUserForm from "./addRoleToUserForm";
import { TeamsAndRolesProvider } from "../../context/dashboardStore";

const orderByUserName = (r1, r2) => {
    if(r1.userName < r2.userName) return -1;
    if(r1.userName > r2.userName) return 1;
    return 0;
};

const isMemberOfTeam = (userId, teams) => {
    for(const team of teams) {
        if(team.members.findIndex(m => m.userId === userId) > -1) return true;
    }
    return false;
}

const RoleCrud = ({roles, teams}) => {
    if(teams.fetching || roles.fetching) return <Loader message="Retrieving Teams and Roles..."/>;
    if(teams.error || roles.error ) return <AppError message="Something wrong happened while retrieving the roles"/>

    const ids = roles.data.map(i => i.userId);
    const userNames = roles.data.map(i => i.userName);

    const infoSyle = {
        paddingTop:"1em"
    };

    return (
        <Segment raised>
            <Label color="teal" ribbon>Add a new user</Label>
            <AddRoleToUserForm existingIds={ids} existingUserNames={userNames}/>
            <Label color="teal" ribbon>Registered users</Label>
            <p style={infoSyle}><i>Users cannot be removed while there are member of a team</i></p>
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell singleLine>User Id</Table.HeaderCell>
                        <Table.HeaderCell singleLine>Username</Table.HeaderCell>
                        <Table.HeaderCell singleLine>Role / Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { roles.data.sort(orderByUserName).map(item => <RoleRow key={item.userId} isMemberOfTeam={isMemberOfTeam(item.userId, teams.data)} item={item} />)}
                </Table.Body>
            </Table>
        </Segment>
    );
}

const RoleClientCrud = () => (
    <TeamsAndRolesProvider>
        {({roles, teams}) => <RoleCrud roles={roles} teams={teams} />}
    </TeamsAndRolesProvider>
);

export default RoleClientCrud;
