import React, { Component } from 'react';
import AuthContext from "./authContext";
import { teamClient } from "../services/graphqlClient";
import AppBearer from "../services/appBearer";
import AppBearerApi from "../security/appBearerApi";
import { clearStoredPath, storeCurrentPath, getStoredPath } from "../services/localRedirectUrlStorage";
import PreAuthLoader from "../components/security/preAuthLoader";
import { PreAuthError, WelcomeBox } from "../components/security/preAuthMessage";
import { UserRole, UserAssignmentQuery } from "../schema/userAssignment";
import config from "../config";

export const withAuthService = (Component) => {
    return function WrapperComponent(props) {
        return (
            <AuthContext.Consumer>
                {({authService}) => <Component {...props} authService={authService} />}
            </AuthContext.Consumer>
        );
    };
};

export const forceReLogin = () => {
    AppBearer.clearBearer();
    window.location.replace( `${config.SMILE_CAS_LOGIN_REDIRECT_URL}`);
};

export default class AuthProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user:AppBearer.isLoggedIn() ? AppBearer.userInfo() : null,
            userAssignment:null,
            authFlowOnError:false,
        };
    }

    componentDidMount() {
        if(AppBearer.isLoggedIn() && !!!this.state.userAssignment) {
            teamClient.query(UserAssignmentQuery).toPromise().then(r => {
                const userAssignment = {
                    role:r.data.UserAssignment.role,
                    teams:r.data.UserAssignment.teams
                };

                this.setState({userAssignment, authFlowOnError:false});
            }).catch(e => this.setState({
                authFlowOnError: true,
                userAssignment:{ role:UserRole.NONE, teams:[] }
            }));
        }
    }

    isLoggedIn() {
        return AppBearer.isLoggedIn();
    }

    getUser() {
        return this.state.user;
    }

    getUserAssignment() {
        return this.state.userAssignment;
    }

    async getBearerFromTicket(ticket) {
        return AppBearerApi.getBearerFromTicket(ticket).then(bearer => {
            AppBearer.setBearer(bearer);
            return bearer;
        });
    }

    login(redirecToOrigin= false) {
        if(redirecToOrigin) storeCurrentPath();
        window.location.replace( `${config.SMILE_CAS_LOGIN_REDIRECT_URL}`);
    }

    postLoginRedirect() {
        const path = getStoredPath() || '/';
        console.log(`AUTH-PROVIDER : Post login redirect to : ${path}`);
        clearStoredPath();
        window.location.replace(path);
    }

    logout() {
        AppBearer.clearBearer();
        window.location.replace(config.SMILE_CAS_LOGOUT_URL);
    }

    render() {

        const authService = {
            isLoggedIn:this.isLoggedIn.bind(this),
            getUser:this.getUser.bind(this),
            getBearerFromTicket:this.getBearerFromTicket.bind(this),
            getUserAssignment:this.getUserAssignment.bind(this),
            login:this.login.bind(this),
            postLoginRedirect:this.postLoginRedirect.bind(this),
            logout:this.logout.bind(this),
        };

        // Error information
        if(this.state.authFlowOnError) {
            const message = "Something went wrong during the authentication process. Please refresh your browser";
            return <PreAuthError message={message} />;
        }

        // Just logged-in, but user assignment not yet retrieved
        if(AppBearer.isLoggedIn() && !!!this.state.userAssignment) {
            return <PreAuthLoader message="Retrieving your access rights..." />
        }

        // Just logged-in, user assignment retrieved, but no user role
        if(AppBearer.isLoggedIn() && this.state.userAssignment) {
            const { role } = this.state.userAssignment;

            if(role === UserRole.NONE) {
                return <WelcomeBox header={"Hi there!"}>
                    <>
                        <p></p>
                        <p>Dear {this.state.user.firstName} welcome, but it looks like you are not yet allowed to get access to this application. </p>
                        <p>To do so, please request your access on <a href="https://helpdesk.smile.fr/" target="_blank" rel="noreferrer">https://helpdesk.smile.fr/</a> (User Support - Infrastructure > demo-factory), including the following information : </p>
                        <ul>
                            <li>userId: {this.state.user.uid}</li>
                            <li>user email: {this.state.user.email}</li>
                        </ul>
                        <p>After access allowed, you just need come back here!</p>
                        <p>See you soon!</p>
                    </>
                </WelcomeBox>
            }
        }

        // Everything else : not yet loggedIn (callback page for ticket handling), or logged-in and proper user role
        return (
            <AuthContext.Provider value={{authService}}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
};

